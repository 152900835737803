import { isEmptyNumsCustom } from '@/js/lib/input-check.js'

const getPositions = (data) => {
    var { μ, σ, z, zLow, zHigh, posHighSkew } = data
    var { posLow, posHigh } = ''

    if (posHighSkew) {
        posLow = posHighSkew
    } else {
        if (isEmptyNumsCustom(zLow, zHigh)) {
            if (z < μ) {
                posLow = 3 - Math.abs((μ - z) / σ)
            } else {
                posLow = Math.abs((μ - z) / σ) + 3
            }
        } else {
            if (zHigh < μ) {
                posLow = 3 - Math.abs((μ - zHigh) / σ)
            } else {
                posLow = Math.abs((μ - zHigh) / σ) + 3
            }
            if (zLow < μ) {
                posHigh = 3 - Math.abs((μ - zLow) / σ)
            } else {
                posHigh = Math.abs((μ - zLow) / σ) + 3
            }
        }
    }
    return { posLow, posHigh }
}

const updatePlotStyle = (k, clips) => {
    var color = 'black'
    var textAlign = 'center'
    var align = 'center'

    if (k === 'zLow') {
        textAlign = 'right'
        align = 'left'
    } else if (k === 'zHigh') {
        textAlign = 'left'
        align = 'right'
    }
    if (!clips.posHigh) {
        if (clips.posLow < 0.67) {
            if (k === 'min') {
                color = 'transparent'
            }
        }
        if (clips.posLow > 5.36) {
            if (k === 'max') {
                color = 'transparent'
            }
        }
    } else {
        if (clips.posHigh < 0.67) {
            if (k === 'min') {
                color = 'black'
            }
        }
        if (clips.posLow > 5.36) {
            if (k === 'max') {
                color = 'black'
            }
        }
    }

    return { color, textAlign, align }
}

const isScewedPlotUpdate = (data, k) => {
    var { z, graphType, computeType } = data
    var text = ''
    if (graphType) {
        if (k === 'max') {
            if (computeType === 'chi') {
                text = '𝛘²'
            } else {
                text = 'F'
            }
        } else if (k === 'min') {
            text = '0'
        } else if (k === 'z' && z) {
            text = Number(z.toFixed(3))
        }
        return text
    }
}

const getIndexPoint = (clip) => {
    var indexPoint
    switch (true) {
    case clip < 0.05:
        indexPoint = 0
        break
    case clip >= 0.05 && clip < 0.59:
        indexPoint = 5
        break
    case clip >= 0.35 && clip < 0.67:
        indexPoint = 10
        break
    case clip >= 0.67 && clip < 1.34:
        indexPoint = 15
        break
    case clip >= 1.34 && clip < 2.01:
        indexPoint = 25
        break
    case clip >= 2.01 && clip < 2.68:
        indexPoint = 35
        break
    case clip >= 2.68 && clip < 3.35:
        indexPoint = 45
        break
    case clip >= 3.35 && clip < 4.00:
        indexPoint = 55
        break
    case clip >= 4.00 && clip < 4.69:
        indexPoint = 65
        break
    case clip >= 4.69 && clip <= 5.36:
        indexPoint = 75
        break
    case clip > 5.36 && clip <= 6:
        indexPoint = 85
        break
    case clip > 6:
        indexPoint = 89
        break
    default:
        indexPoint = 0
    }
    return indexPoint
}

export default {
    getPositions,
    updatePlotStyle,
    isScewedPlotUpdate,
    getIndexPoint
}
