import { isEmptyNums, isEmptyNumsCustom, isInvalidNums, isNotPercentage, isNegative, isValidN } from '@/js/lib/input-check.js'
import errorText from '@/js/lib/error-handle-text.js'
import graph from '@/js/lib/graph.js'
import _ from 'lodash'
var jStat = require('jstat').jStat
var jsstats = require('js-stats')

const setZones = (plot, selected, zLow, zHigh, z, prob, probC) => {
    var { plotLineVals, zLowZone, zHighZone } = ''
    if (isEmptyNumsCustom(prob) && isEmptyNumsCustom(probC)) {
        plotLineVals = [plot.min, plot.max]
        return { plotLineVals, zLowZone, zHighZone }
    }
    if (isEmptyNumsCustom(zLow, zHigh)) {
        // single
        if (selected.value === '<' || selected.value === '≤') {
            zLowZone = plot.min.value
            zHighZone = plot.z.value
        } else {
            zLowZone = plot.z.value
            zHighZone = plot.max.value
        }
        plotLineVals = [plot.z, plot.min, plot.max]
    // between
    } else {
        plotLineVals = [plot.zLow, plot.zHigh, plot.min, plot.max]
        zLowZone = plot.zLow.value
        zHighZone = plot.zHigh.value
    }
    return { plotLineVals, zLowZone, zHighZone }
}

const createPlot = (data) => {
    var positions = graph.getPositions(data)
    var plotData = _.mapValues(data, (v, k) => {
        if (data.z && data.x && k === 'z') {
            v = data.x
        }
        const vNum = _.toNumber(v)
        var text = graph.isScewedPlotUpdate(data, k) || Number(vNum.toFixed(3))
        var { color, textAlign, align } = graph.updatePlotStyle(k, positions)
        var item = {
            value: vNum,
            width: 0,
            label: {
                text,
                textAlign,
                align,
                verticalAlign: 'bottom',
                rotation: 0,
                y: 20,
                style: {
                    color,
                    fontSize: 12,
                    fontWeight: 'bold',
                }
            }
        }
        return item
    })
    return plotData
}

const loadGraphSeries = (μ, σ) => {
    const min = μ - 3 * σ
    const max = μ + 3 * σ
    const unit = (max - min) / 100
    const points = _.range(min, max, unit)

    const normalY = (x, μ, σ) => Math.exp((-0.5) * Math.pow((x - μ) / σ, 2))
    const seriesData = points.map(x => ({
        x,
        y: normalY(x, μ, σ),
        name: x,
    }))
    return seriesData
}

const loadSkewedGraphSeries = (μ, σ) => {
    const min = -2.75
    const max = μ + 3 * σ
    const unit = (max - min) / 90
    const points = _.range(min, max, unit)

    const normalY = (x, μ, σ) => Math.exp((-0.15) * Math.pow((x - μ) / σ, 4))
    const normalYOffset = (x, μ, σ) => Math.exp((-0.5) * Math.pow((x - μ) / σ, 2))
    const seriesData = points.map(x => ({
        x,
        y: (x >= μ ? (normalYOffset(x, μ, σ)) : normalY(x * 0.6, μ, 0.6)),
        name: x,
    }))
    return seriesData
}

const calculateGraphData = (data, μ, σ) => {
    var { z, n, zLow, zHigh, prob, probC, selected, graphType, computeType, posHighSkew, choiceData, tabIndex } = data
    μ = _.toNumber(μ)
    σ = _.toNumber(σ)
    var { series, plot } = ''

    // Normal xBar only
    if (tabIndex === 2) {
        σ = σ / Math.sqrt(_.toNumber(n))
    }

    const min = μ - 3 * σ
    const max = μ + 3 * σ

    // Hypothesis Testing
    if (choiceData) { // two samples
        computeType = choiceData.computeType
        selected = choiceData.selected
    }
    // HT Not Equal
    if (computeType === 'hypothesis' && selected.value === '≠') {
        zLow = z * -1
        zHigh = z
        if (zHigh < zLow) {
            [zHigh, zLow] = [zLow, zHigh]
        }
    }
    // Chi & F Distribution
    if (graphType === 'normalSkewed') {
        series = loadSkewedGraphSeries(μ, σ)
        var point = _.get(series, [data.seriesPoint])
        const x = point?.x
        plot = createPlot({ z, x, min, max, graphType, computeType, posHighSkew })
    // Normal Distributions
    } else {
        series = loadGraphSeries(μ, σ)
        plot = createPlot({ z, μ, σ, zLow, zHigh, min, max })
    }
    var zones = setZones(plot, selected, zLow, zHigh, z, prob, probC)

    return { series, zones, min, max }
}

const xComputed = (data) => {
    var { parsedμ, parsedσ, parsedZ, parsedprob, parsedZLow, parsedZHigh, parsedprobC } = data
    // prob
    const calculatedZ = (parsedZ - parsedμ) / parsedσ
    const zFixed = calculatedZ.toFixed(2)
    const prob = jStat.normal.cdf(zFixed, 0, 1)
    // x
    const zInverse = jStat.normal.inv(parsedprob, 0, 1)
    const zInverseFixed = Number(zInverse.toFixed(2))
    const less = parsedμ + (zInverseFixed * parsedσ)
    const greater = (parsedμ - less) + parsedμ
    // prob between
    const zLow = (parsedZLow - parsedμ) / parsedσ
    const zHigh = (parsedZHigh - parsedμ) / parsedσ
    const zLowNew = zLow.toFixed(2)
    const zHighNew = zHigh.toFixed(2)
    const probLow = jStat.normal.cdf(zLowNew, 0, 1)
    const probHigh = jStat.normal.cdf(zHighNew, 0, 1)
    var probBetween = probHigh - probLow
    // x between
    var calculatedProbC = (parsedprobC / 2) + 0.50
    const z = jStat.normal.inv(calculatedProbC, 0, 1)
    const zNew = z.toFixed(2)
    const high = parsedμ + (zNew * parsedσ)
    const low = parsedμ - (zNew * parsedσ)

    return { z: parsedZ, prob, less, greater, probBetween, high, low }
}

const xBarComputed = (data) => {
    var { parsedμ, parsedσ, parsedn, parsedZ, parsedprob, parsedZLow, parsedZHigh, parsedprobC } = data
    // prob
    const calculatedZ = (parsedZ - parsedμ) / (parsedσ / Math.sqrt(parsedn))
    const zFixed = calculatedZ.toFixed(2)
    const prob = jStat.normal.cdf(zFixed, 0, 1)
    // xbar
    const zInverse = jStat.normal.inv(parsedprob, 0, 1)
    const zInverseFixed = zInverse.toFixed(2)
    const less = parsedμ + zInverseFixed * (parsedσ / Math.sqrt(parsedn))
    const greater = (parsedμ - less) + parsedμ
    // prob between
    const newSigma = parsedσ / Math.sqrt(parsedn)
    const zLow = (parsedZLow - parsedμ) / newSigma
    const zHigh = (parsedZHigh - parsedμ) / newSigma
    const zLowNew = zLow.toFixed(2)
    const zHighNew = zHigh.toFixed(2)
    const probLow = jStat.normal.cdf(zLowNew, 0, 1)
    const probHigh = jStat.normal.cdf(zHighNew, 0, 1)
    const probBetween = probHigh - probLow
    // xBar between
    var calculatedProbC = (parsedprobC / 2) + 0.50
    const z = jStat.normal.inv(calculatedProbC, 0, 1)
    const zNew = z.toFixed(2)
    const high = parsedμ + (zNew * newSigma)
    const low = parsedμ - (zNew * newSigma)

    return { z: parsedZ, prob, less, greater, probBetween, high, low }
}

const zComputed = (data) => {
    var { parsedμ, parsedσ, parsedZ, parsedprob, parsedZLow, parsedZHigh, parsedprobC } = data

    parsedZ = handleNegativeRounding(parsedZ)

    // prob
    const calculatedZ = (parsedZ - parsedμ) / parsedσ
    const prob = jStat.normal.cdf(calculatedZ, parsedμ, parsedσ)
    // z
    const less = jStat.normal.inv(parsedprob, parsedμ, parsedσ)
    var greater = 0
    if (less <= parsedμ) {
        greater = (parsedμ - (less)) + parsedμ
    }
    if (less > parsedμ) {
        greater = parsedμ - (less - parsedμ)
    }
    // prob between
    const parsedZLowFixed = handleNegativeRounding(parsedZLow)
    const parsedZHighFixed = handleNegativeRounding(parsedZHigh)
    const probLow = jStat.normal.cdf(parsedZLowFixed, parsedμ, parsedσ)
    const probHigh = jStat.normal.cdf(parsedZHighFixed, parsedμ, parsedσ)
    const probBetween = probHigh - probLow
    // z between
    const calculatedProbC = (parsedprobC / 2) + 0.50
    const z = jStat.normal.inv(calculatedProbC, parsedμ, parsedσ)
    const high = z
    const low = z * -1

    return {
        z: parsedZ,
        prob,
        less: less.toFixed(2),
        greater: greater.toFixed(2),
        probBetween,
        high: high.toFixed(2),
        low: low.toFixed(2),
        parsedZLowFixed,
        parsedZHighFixed
    }
}

const tComputed = (data) => {
    var { parsedμ, parsedσ, parsedDf, parsedZ, parsedprob, parsedZLow, parsedZHigh, parsedprobC } = data

    parsedZ = handleNegativeRounding(parsedZ)

    // prob
    const calculatedZ = (parsedZ - parsedμ) / parsedσ
    const zFixed = _.toNumber(calculatedZ.toFixed(2))
    const prob = jStat.studentt.cdf(zFixed, parsedDf)
    // t
    const less = jStat.studentt.inv(parsedprob, parsedDf)
    var greater = 0
    if (less <= parsedμ) {
        greater = (parsedμ - (less)) + parsedμ
    }
    if (less > parsedμ) {
        greater = parsedμ - (less - parsedμ)
    }
    // prob between
    const parsedZLowFixed = handleNegativeRounding(parsedZLow)
    const parsedZHighFixed = handleNegativeRounding(parsedZHigh)
    const probLow = jStat.studentt.cdf(parsedZLowFixed, parsedDf)
    const probHigh = jStat.studentt.cdf(parsedZHighFixed, parsedDf)
    const probBetween = probHigh - probLow

    // t between
    const calculatedProbC = (parsedprobC / 2) + 0.50
    const tdistribution = new jsstats.TDistribution(parsedDf)
    var tdf = tdistribution.invCumulativeProbability(calculatedProbC)
    const high = tdf
    const low = tdf * -1

    return {
        z: parsedZ,
        prob,
        less: less.toFixed(2),
        greater: greater.toFixed(2),
        probBetween,
        high: high.toFixed(2),
        low: low.toFixed(2),
        parsedZLowFixed,
        parsedZHighFixed
    }
}

const getComputed = (data, tabIndex) => {
    var output
    if (tabIndex === 2) {
        output = xBarComputed(data)
    } else if (tabIndex === 1) {
        output = xComputed(data)
    } else if (tabIndex === 't') {
        output = tComputed(data)
    } else {
        output = zComputed(data)
    }
    return output
}

const calculateData = (data, μ, σ) => {
    // Note: z represents (z | x | xbar | t) in calculations
    var { z, n, df, zLow, zHigh, prob, probC, selected, tabIndex } = data

    tabIndex = _.get(data, 'tabIndex', data.computeType) // Computations outside of normal
    var output = {}
    var value = selected.value
    var parsedZLow = Number(zLow)
    var parsedZHigh = Number(zHigh)
    var parsedZ = Number(z)
    var parsedσ = Number(σ)
    var parsedμ = Number(μ)
    var parsedn = Number(n)
    var parsedDf = Number(df)
    var parsedprob = Number(prob)
    var parsedprobC = Number(probC)
    var tempProb = ''

    var parsedData = { parsedμ, parsedσ, parsedDf, parsedn, parsedZ, parsedprob, parsedZLow, parsedZHigh, parsedprobC }
    var computedVals = getComputed(parsedData, tabIndex)

    // Calculation for probability
    if ((z !== '' && prob === '') || !isEmptyNumsCustom(z, prob)) {
        if (isInvalidNums(parsedZ)) { return }
        if (isEmptyNums(z)) { return }

        if (value === '<' || value === '≤') {
            _.set(output, 'data', {
                z: computedVals.z,
                prob: computedVals.prob.toFixed(4),
            })
        } else if (value === '>' || value === '≥') {
            tempProb = 1 - computedVals.prob
            _.set(output, 'data', {
                z: computedVals.z,
                prob: tempProb.toFixed(4),
            })
        }
    // Calculation for point
    } else if (prob !== '' && z === '') {
        if (isNotPercentage(parsedprob)) { return }
        if (isInvalidNums(parsedprob)) { return }

        const [less, greater] = setDecimals(computedVals.less, computedVals.greater)

        if (value === '<' || value === '≤') {
            _.set(output, 'data', {
                z: less,
            })
        } else if (value === '>' || value === '≥') {
            _.set(output, 'data', {
                z: greater,
            })
        }
    }

    // Calculation for probability (between)
    if (zHigh !== '' && zLow !== '' && prob === '') {
        if (isInvalidNums(parsedZHigh, parsedZLow)) { return }
        if (isEmptyNums(zHigh, zLow)) { return }

        const probBetween = computedVals.probBetween
        if (probBetween < 0) {
            alert(errorText.distributionAlert.betweenAlert)
            return
        }

        const { parsedZLowFixed, parsedZHighFixed } = computedVals // z & t

        _.set(output, 'data', {
            zLow: parsedZLowFixed || zLow,
            zHigh: parsedZHighFixed || zHigh,
            probC: probBetween.toFixed(4)
        })
        // calculation for points (between)
    } else if (zHigh === '' && zLow === '' && probC !== '') {
        if (isNotPercentage(parsedprobC)) { return }
        if (isInvalidNums(parsedprobC)) { return }

        const [low, high] = setDecimals(computedVals.low, computedVals.high)

        _.set(output, 'data', {
            zLow: low,
            zHigh: high,
        })
    } else if ((zHigh === '' && zLow !== '') || (zHigh !== '' && zLow === '')) {
        alert(errorText.distributionAlert.missingBetweenAlert)
        return
    }

    output = setOutputDataType(output.data)
    return output
}

const handleNegativeRounding = (value) => {
    const absValue = _.round(Math.abs(value), 2)
    return value < 0 ? -absValue : absValue
}

const setDecimals = (...values) => {
    // sets to 4 decimal places if not set as 2 already (z, t)
    return _.map(values, val => {
        if (!_.isString(val)) {
            return val.toFixed(4)
        }
        return val
    })
}

const setOutputDataType = (output) => {
    // convert values to numbers if not already strings
    output = _.mapValues(output, (value) => {
        return _.isString(value) ? value : _.toNumber(value)
    })
    return output
}

const checkMuSigma = (μ, σ) => {
    var parsedσ = Number(σ)
    var parsedμ = Number(μ)
    if (isEmptyNumsCustom(μ, σ)) {
        alert(errorText.distributionAlert.firstInputx)
        return true
    }
    if (isEmptyNums(μ, σ) || isInvalidNums(parsedμ, parsedσ) || isNegative(parsedσ)) {
        return true
    }
}

const checkN = (μ, σ, n) => {
    var parsedn = Number(n)
    if (isEmptyNumsCustom(μ, σ, n)) {
        alert(errorText.distributionAlert.firstInputxBar)
        return true
    }
    if (isEmptyNums(n) || isInvalidNums(parsedn) || isValidN(parsedn)) {
        return true
    }
}

export default {
    calculateGraphData,
    calculateData,
    loadGraphSeries,
    loadSkewedGraphSeries,
    checkMuSigma,
    checkN
}
